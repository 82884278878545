import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as enMessages } from './locales/en/messages'
import { messages as itMessages } from './locales/it/messages'
// import './index.css'
import App from './App'

const container = document.getElementById('root')

i18n.load({
  en   : enMessages
  , it : itMessages
})
i18n.activate('it')

createRoot(container).render(
  <StrictMode>
    <BrowserRouter>
      <I18nProvider i18n={i18n}>
        <App />
      </I18nProvider>
    </BrowserRouter>
  </StrictMode>)
