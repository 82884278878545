import { useEffect, useState } from 'react'
import { Trans } from '@lingui/macro'
import { useInit } from '../../context/initContext'
import {
  Badge
  , Box
  , Button
  , CircularProgress
  , Drawer as MuiDrawer
  , IconButton
  , styled
  , Typography
  , useTheme
} from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import SaveIcon from '@mui/icons-material/Save'
// import SaveAsOutlinedIcon from '@mui/icons-material/SaveAsOutlined'
import SaveAsIcon from '@mui/icons-material/SaveAs'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined'
import PersonIcon from '@mui/icons-material/Person'
import PersonOutlinedIcon from '@mui/icons-material/PersonOutline'
// import InfoIcon from '@mui/icons-material/Info'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
// import EditRoadIcon from '@mui/icons-material/EditRoad'
import EditRoadSharpIcon from '@mui/icons-material/EditRoadSharp'
// import TrafficOutlinedIcon from '@mui/icons-material/TrafficOutlined'

import Form from './Form'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'

export default function FormContainer ({
  token
  , open
  , isMobile
  , isLoading
  , formList
  , formModel
  , form
  // , control
  , submitRef
  , handleAppend
  , handleFormOpen
  , handleSetFormNumber
  , handleCrashSubmit
  , handlePositioningState
  // , children
}) {
  // const { init } = useInit()
  const location = useLocation()
  const { init } = location.state
  const { control, setValue, formState, getValues, reset } = useFormContext()
  // const formValues = getValues()
  const theme = useTheme()
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const toolbarHeight = theme.mixins.toolbar.minHeight
  // const [showInfoBadge, setShowInfoBadge] = useState(false)
  // const [showRoadBadge, setShowRoadBadge] = useState(false)
  const [unitsBadge, setUnitsBadge] = useState(0)
  const [peopleBadge, setPeopleBadge] = useState(0)

  console.log(formList, form, formModel)
  const { append } = useFieldArray({
    control
    , name: form.type === 'units' || form.type === 'people'
      ? `${formList[form.number].split('.').slice(0, 2).join('.')}.people`
      : form.type
  })

  // children = Children.map(children, el => cloneElement(el, { submitRef, handleSubmit }))
  // if (form.type === 'units') {
  //   peopleMethods = useFieldArray({ control: formArray.control, name: `units.${formList[form.number]}.people` })
  // }

  // useEffect(() => {
  //   useFieldArray({ control: formArray.control, name: `units.${formList[form.number]}.people` })
  // }, [])

  useEffect(() => {
    console.log('FORM STATE', formState)
    console.log('FORM VALUES', getValues())
    if (Object.prototype.hasOwnProperty.call(getValues(), 'units')) {
      setUnitsBadge(getValues().units.length)
    }
    // in case of people inside units
    if (Object.prototype.hasOwnProperty.call(getValues(), 'people')) {
      setPeopleBadge(getValues().people.length)
    }
  }, [formState, getValues])

  let Drawer
  if (isMobile) {
    Drawer = styled(MuiDrawer)({
      '& .MuiDrawer-paper': { height: `calc(100% - ${toolbarHeight}px)` }
    })
  } else {
    Drawer = styled(MuiDrawer)({
      position               : 'relative' // imp
      , width                : formDrawerWidth
      , '& .MuiDrawer-paper' : {
        width        : formDrawerWidth
        , height     : '100vh'
        , position   : 'absolute' // imp
        , transition : 'none !important'
      }
    })
  }

  return (
    <Drawer
      variant="persistent"
      open={open}
      anchor={isMobile ? 'bottom' : undefined}
    >
      <Box display='flex' flexDirection='column' p={1}>
        <Typography width='100%' variant='button' fontWeight={700} textAlign='center'>
          <Trans>NEW CRASH RECORD</Trans>
        </Typography>
        <Typography textTransform='uppercase' width='100%' variant='h6' fontWeight={600} textAlign='center'>
          {form.displayName}
        </Typography>
        <Box mt={1} display='flex' justifyContent='space-evenly' alignItems='center' width='100%'>
          <Badge
            color='success'
            overlap='circular'
            variant='dot'
            // invisible={user.role !== 'admin'}
          >
            <IconButton onClick={() => console.log('Click!') }>
              <InfoOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large' } />
            </IconButton>
          </Badge>
          {Object.prototype.hasOwnProperty.call(formModel, 'road') &&
            <Badge
            color='success'
            overlap='circular'
            variant='dot'
          // invisible={user.role !== 'admin'}
          >
            <IconButton onClick={() => console.log('Click!')}>
              <EditRoadSharpIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
            </IconButton>
          </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'units') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={unitsBadge}
            >
              <IconButton onClick={() => console.log('Click!')}>
                <DirectionsCarOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          {Object.prototype.hasOwnProperty.call(formModel, 'people') &&
            <Badge
              color='primary'
              overlap='circular'
              badgeContent={peopleBadge}
            >
              <IconButton onClick={() => console.log('Click!')}>
                <PersonOutlinedIcon color='primary' fontSize={isMobile ? 'medium' : 'large'} />
              </IconButton>
            </Badge>}
          <IconButton onClick={() => {
            if (!isMobile) reset()
            handlePositioningState(false)
            handleFormOpen(false)
          }}>
            <CloseIcon color='primary' fontSize={isMobile ? 'medium' : 'large' } />
          </IconButton>
        </Box>
      </Box>
      <Box overflow='auto'>
        {/* {children} */}
        <Form
          token={token}
          isMobile={isMobile}
          submitRef={submitRef}
          formModel={formModel}
          formList={formList}
          form={form}
          // unitsFormArray={{ fields: unitsMethods.fields }}
          handleCrashSubmit={handleCrashSubmit}
          handleAppend={handleAppend}
        />
        <Box display='flex' flexDirection='column' gap={1} mt={2} mb={2} pl={1} pr={1}>
          {isMobile && isLoading && <CircularProgress />}
          {form.type === 'crash' && init.user.organization === 2 &&
            <Button variant='contained' onClick={() => {
              handleAppend('people')
            }}>
              <PersonIcon />
              <Typography variant='h6' fontWeight={700}>+</Typography>
            </Button>}
          {form.type === 'road' &&
            <Button variant='contained' onClick={() => {
              handleAppend('units')
            }}>
              <DirectionsCarIcon />
              <Typography variant='h6' fontWeight={700}>+</Typography>
            </Button>}
          {form.type === 'units' &&
            <>
              <Button variant='contained' onClick={() => {
                handleAppend('units')
              }}>
                <DirectionsCarIcon />
                <Typography variant='h6' fontWeight={700}>+</Typography>
              </Button>
              <Button variant='contained' onClick={() => {
                handleAppend('unitPeople', formList[form.number], append)
              }}>
                <PersonIcon />
                <Typography variant='h6' fontWeight={700}>+</Typography>
              </Button>
            </>}
          {form.type === 'people' && init.user.organization === 1 &&
            <Button variant='contained' onClick={() => {
              handleAppend('unitPeople', formList[form.number], append)
            }}>
              <PersonIcon color='error' />
              <Typography variant='h6' fontWeight={700}>+</Typography>
            </Button>}
          {form.type === 'people' && init.user.organization === 2 &&
            <Button variant='contained' onClick={() => {
              handleAppend('people', formList[form.number], append)
            }}>
              <PersonIcon />
              <Typography variant='h6' fontWeight={700}>+</Typography>
            </Button>}
        </Box>
        <Box display='flex' justifyContent='space-evenly' mt={3} mb={2}>
          {isMobile && isLoading
            ? <CircularProgress />
            : <>
                <Button
                  variant='contained'
                  disabled={form.number === 0}
                  onClick={() => {
                    setValue('isDraft', true)
                    submitRef.current.requestSubmit()
                    handleSetFormNumber(-1)
                  }}
                >
                  <ArrowLeftIcon />
                </Button>
                <Button
                variant='contained'
                onClick={() => {
                  setValue('isDraft', false)
                  setValue('isFinal', true)
                  submitRef.current.requestSubmit()
                }}
                >
                  <SaveIcon />
                </Button>
              <Button
                variant='contained'
                onClick={() => {
                  setValue('isDraft', true)
                  setValue('isFinal', true)
                  submitRef.current.requestSubmit()
                }}
              >
                  <SaveAsIcon />
                </Button>
                <Button
                  variant='contained'
                  disabled={form.number === formList.length - 1}
                  onClick={() => {
                    setValue('isDraft', true)
                    submitRef.current.requestSubmit()
                    handleSetFormNumber(1)
                  }}
                >
                  <ArrowRightIcon />
                </Button>
              </>}
        </Box>
      </Box>
    </Drawer>
  )
}
