import { useCallback, useEffect, useState } from 'react'
import { Box, Divider, FormControl, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDateTimePicker, DateTimePicker, renderTimeViewClock, DatePicker, MobileDatePicker } from '@mui/x-date-pickers/'
import { useFormContext, Controller } from 'react-hook-form'
import moment from 'moment'

const DynamicForm = ({ isMobile, formModel, form, formList }) => {
  const { control } = useFormContext()

  return (
    <>
      {formModel[form.type].elements
        .filter(elem => (elem.type !== 'coordinates' &&
          elem.label !== 'Crash id' &&
          elem.label !== 'Report date' &&
          elem.label !== 'Crash date'
        ))
        .map(elem => {
          if (elem.type === 'datetime') {
            return (
              <FormControl key={`${form.type}-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DateTimePicker
                          label={elem.label}
                          disableFuture
                          value={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          viewRenderers={{
                            hours   : renderTimeViewClock
                            , minutes : renderTimeViewClock
                            , seconds : renderTimeViewClock
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          if (elem.type === 'date') {
            return (
              <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <Controller
                    name={`${formList[form.number]}.${elem.label}`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      isMobile
                        ? <MobileDatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                        : <DatePicker
                          label={elem.label}
                          disableFuture
                          defaultValue={value ? moment(value) : null}
                          onAccept={date => {
                            onChange(date)
                          }}
                          sx={{ marginTop: 1 }}
                        />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
            )
          }

          /* it could need to be unregistered */
          // if (elem.type === 'divider') {
          //   return (<Divider key={`${form.type}-${elem.type}`} aria-hidden='true' sx={{ margin: '1rem 0' }} />)
          // }

          if (elem.type === 'select') {
            return (
            <FormControl key={`${elem.type}-${elem.label}`} margin='normal'>
              <InputLabel id={`select-${elem.label}`}>{elem.label}</InputLabel>
              <Controller
                name={`${formList[form.number]}.${elem.label}`}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId={`select-${elem.label}`}
                    id={`select-${elem.label}`}
                    label={elem.label}
                    value={field.value}
                    onChange={field.onChange}
                    startAdornment={elem.prefix && <InputAdornment>{elem.prefix}&nbsp;</InputAdornment>}
                  >
                    {elem.options.map(opt => {
                      if (opt.value && opt.value % 100 === 0) {
                        return <ListSubheader>{opt.label}</ListSubheader>
                      }
                      return <MenuItem key={opt.label} value={opt.value}>{opt.label}</MenuItem>
                    })}
                  </Select>
                )}
              />
            </FormControl>
            )
          }

          return (
          <FormControl key={`${elem.type}-${elem.label}`}>
            <Controller
              name={`${formList[form.number]}.${elem.label}`}
              control={control}
              render={({ field }) => (
                <TextField
                  margin={elem.margin || 'normal'}
                  fullWidth
                  required={elem.isRequired}
                  id={elem.label}
                  label={elem.label}
                  value={field.value}
                  onChange={field.onChange}
                  {...field}
                />
              )}
            />
          </FormControl>
          )
        })}

      {/* {formModel[form.type].hierarchy === 1 &&
        <Button variant='contained' onClick={() => {
          handleAppend('people', formModel[form.number])
        }}>
          <PersonIcon />
          <Typography variant='h6' fontWeight={700}>+</Typography>
        </Button>} */}
    </>
  )
}

// const DynamicNestedForm = ({ isMobile, formModel, form, formList, firstLevIndex, handleAppend }) => {
//   const { control } = useFormContext()
//   console.log(formList)
// const unitsIndex = unitsFormArray?.fields.length - 1
// const [name] = useState(form.type === 'people' || form.type === 'units'
//   ? `${formList[form.number]}.people`
//   : form.type === 'road'
//     ? 'units'
//     : formList[form.number]
// )
// const [parent] = useState(form.type === 'people'
//   ? name.split('.').slice(0, 2).join('.')
//   : formList[form.number]
// )

// const { fields } = useFieldArray({
//   control
//   , name: `units.${firstLevIndex}.people`
// })

// nestedArrayMethods.append(defaults.people.map(person => ({ ...person, 'Person id': lastIndex + 1 })))
//   return (
//     <>
//       <DynamicForm
//         isMobile={isMobile}
//         form={form}
//         formModel={formModel}
//         formList={formList}
//         handleAppend={handleAppend}
//       />
//       <Button variant='contained' onClick={() => {
//         handleAppend('people', formModel[form.number])
//       }}>
//         <PersonIcon />
//         <Typography variant='h6' fontWeight={700}>+</Typography>
//       </Button>
//     </>
//   )
// }

const Form = ({
  token
  , isMobile
  , submitRef
  , form
  , formModel
  , formList
  , formArray
  , handleAppend
  , handleCrashSubmit
}) => {
  const { control, setValue, handleSubmit, watch } = useFormContext()
  const watchCrashDate = watch('crash.Crash date')
  const watchReportDate = watch('crash.Report date')

  console.log(formArray, form.type)

  const getNewId = useCallback(date => {
    // eslint-disable-next-line no-restricted-globals
    const newCrashIdUrl = new URL('/api/crash/new_crash_id', location)
    // newCrashIdUrl.searchParams.set('date', moment(date).format('YYYY-MM-DD'))

    fetch(newCrashIdUrl, {
      headers: {
        Authorization  : `Bearer ${token}`
        , 'Content-Type' : 'application/json'
      }
    })
      .then(res => res.json())
      .then(res => {
        // setValue('crash.Progressive', res.data?.newId)
        // const newId = `${moment(date).format('YYYYMMDD')}_${res.data?.newId.toString().padStart(5, '0')}`
        // setValue('crash.Crash id', newId) // Set the value of Crash id
        setValue('crash.Crash id', res.crash_id) // Set the value of Crash id
      })
  }, [token, setValue])

  useEffect(() => {
    if (form.type === 'crash' && !Object.keys(watchReportDate).length) {
      setValue('crash.Report date', moment())
    }
  }, [watchReportDate, form.type, setValue])

  useEffect(() => {
    if (form.type === 'crash') {
      if (!Object.keys(watchCrashDate).length) setValue('crash.Crash date', moment())
      getNewId(watchCrashDate)
    }
  }, [watchCrashDate, form.type, getNewId, setValue])

  return (
    <Box
      name={form.type}
      component="form"
      ref={submitRef}
      onSubmit={handleSubmit(handleCrashSubmit)}
      display='flex'
      flexDirection='column'
      pl={1}
      pr={1}
      noValidate
    >
      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Report date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name={'crash.Report date'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDatePicker
                        label={elem.label}
                        disableFuture
                        value={value ? moment(value) : moment()}
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DatePicker
                        label={elem.label}
                        disableFuture
                        value={value ? moment(value) : moment()}
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && <Divider key='crash-divider' aria-hidden='true' sx={{ margin: '1rem 0' }} />}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash id')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`${form.type}-${elem.type}-${elem.label}`}>
              <Controller
                name={`${form.type}.Crash id`}
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    margin={elem.margin || 'normal'}
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.label === 'Crash date')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`} margin={elem.margin || 'normal'}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Controller
                  name={`${form.type}.${elem.label}`}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    isMobile
                      ? <MobileDateTimePicker
                        label={elem.label}
                        disableFuture
                        value={moment(value)}
                        onAccept={date => {
                          onChange(date)
                        }}
                        sx={{ marginTop: 1 }}
                      />
                      : <DateTimePicker
                        label={elem.label}
                        disableFuture
                        value={moment(value)}
                        onAccept={date => {
                          onChange(date)
                        }}
                        viewRenderers={{
                          hours   : renderTimeViewClock
                          , minutes : renderTimeViewClock
                          , seconds : renderTimeViewClock
                        }}
                        sx={{ marginTop: 1 }}
                      />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
        ))}

      {form.type === 'crash' && formModel.crash.elements
        .filter(element => element.type === 'coordinates')
        .map(elem => ( // This gets filled automatically
            <FormControl key={`crash-${elem.type}-${elem.label}`}>
              <Controller
                name={`${form.type}.${elem.label}`}
                control={control}
                render={({ field }) => (
                  <TextField
                    margin={elem.margin || 'normal'}
                    fullWidth
                    required={elem.isRequired}
                    id={elem.label}
                    label={elem.label}
                    value={field.value}
                    InputProps={{ readOnly: true }}
                    InputLabelProps={{ shrink: true }}
                    {...field}
                  />
                )}
              />
            </FormControl>
        ))}

        <DynamicForm
          isMobile={isMobile}
          formModel={formModel}
          formList={formList}
          form={form}
          formArray={formArray}
          // handleAppend={handleAppend}
        />
    </Box>
  )
}

export default Form
