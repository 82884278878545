import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { msg, Trans, t } from '@lingui/macro'
import {
  Alert
  , Box
  , Button
  , Container
  , CssBaseline
  , Skeleton
  // , Toolbar
  , Typography
  , useTheme
} from '@mui/material/'
import {
  GridRowModes
  , DataGrid
  , GridToolbarContainer
  , GridActionsCellItem
  , GridRowEditStopReasons
} from '@mui/x-data-grid'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
import AddIcon from '@mui/icons-material/Add'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
// import MobileTopNav from '../components/mobile/MobileTopNav'
import DesktopNav from '../components/desktop/DesktopNav'
import { randStr } from '../utils'
import OrganizationIcon from '../components/OrganizationIcon'
// import ConfigTable from '../components/configTable'

// eslint-disable-next-line no-restricted-globals
const getUrl = new URL('/api/users', location)
// eslint-disable-next-line no-restricted-globals
const postUrl = new URL('/api/user', location)

// Editable fields by user
// name
// surname
// department
// password
// is_verified

// Example user
// id: 1
// username: ""
// name: ""
// surname: ""
// organization: 0
// department: ""
// phone: "00000000"
// email: ""
// type: 1
// create_date: "2024-09-06"
// is_verified: false

const paginationModel = { page: 0, pageSize: 5 }

function TableSkel () {
  return ( // ? <CircularProgress sx={{ mt: 2 }} />
    <>
      <Skeleton width='100%' sx={{ mt: 2, fontSize: '2rem' }} />
      {Array.from({ length: 5 }).map((_, i) => <Skeleton key={i} width='100%' />)}
      {/* <Skeleton width='100%' sx={{ fontSize: '2rem' }} /> */}
    </>
  )
}

function FakeToolbar () {
  return (
    <GridToolbarContainer>
      <div style={{ height: '1px' }} />
    </GridToolbarContainer>
  )
}

function EditToolbar ({ section, setRows, setRowModesModel }) {
  const handleClick = () => {
    const id = randStr()
    setRows(oldConfig => [
      {
        id
        , section
        , label      : ''
        , isRequired : false
        , type       : 'text'
        , options    : []
        , unit       : ''
        , value      : ''
        , isNew      : true
      }
      , ...oldConfig
    ])
    setRowModesModel(oldModel => ({
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'label' }
      , ...oldModel
    }))
  }

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        <Trans>Add item</Trans>
      </Button>
    </GridToolbarContainer>
  )
}

export default function Admin ({ isMobile }) {
  const navigate = useNavigate()
  const location = useLocation()
  const logout = useLogout()
  const token = location.state?.token || null
  const init = location.state?.init || {}
  const organizations = location.state?.organizations || {}
  const { configuration } = init
  const theme = useTheme()
  const { _ } = useLingui()
  const drawerWidth = theme.mixins.drawerWidth
  const { isLoading, error, sendRequest, reset } = useHttp()
  const [userRows, setUserRows] = useState([])
  const [userRowModesModel, setUserRowModesModel] = useState({})
  const [crashRows, setCrashRows] = useState([])
  const [crashRowModesModel, setCrashRowModesModel] = useState({})
  const [roadRows, setRoadRows] = useState([])
  const [roadRowModesModel, setRoadRowModesModel] = useState({})
  const [unitsRows, setUnitsRows] = useState([])
  const [unitsRowModesModel, setUnitsRowModesModel] = useState({})
  const [peopleRows, setPeopleRows] = useState([])
  const [peopleRowModesModel, setPeopleRowModesModel] = useState({})
  // const [rowModesModel, setRowModesModel] = useState({})
  const [message, setMessage] = useState('')

  useEffect(() => {
    if (!token) return logout()
    if (isMobile) return navigate('/home', { state: { token } })
  }, [isMobile, navigate, token, logout])

  useEffect(() => {
    let confSections = {}
    Object.keys(configuration)
      .forEach(key => {
        confSections = {
          ...confSections
          , [key]: configuration[key].elements
            .map((element, i) => ({ ...element, id: i + 1, section: key }))
        }
      })
    console.log('CONF', confSections)
    setCrashRows(confSections.crash)
    setRoadRows(confSections.road)
    setUnitsRows(confSections.units)
    setPeopleRows(confSections.people)
  }, [configuration])

  const getUsers = useCallback(async () => {
    await sendRequest({
      token
      , url           : getUrl
      , manageResData : res => {
        console.log('USERSSSS', res)
        setUserRows(res.users)
      }
    })
  }, [token, sendRequest])

  useEffect(() => {
    getUsers()
  }, [getUsers])

  const subPanelElements = [
    {
      text       : t`Logout`
      , icon     : <LogoutSharpIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : logout
    }
  ]

  const modifyUser = data => {
    let { id, name, surname, organization, department, departmentAlt, verified } = data
    if (organization === 2) department = departmentAlt
    const body = { id, verified, name, surname, department }
    console.log('BODDDY', body)
    const options = {
      token
      , url           : postUrl
      , httpBody      : body
      , httpMethod    : 'POST'
      , manageResData : res => {
        if (res.result.includes('success')) {
          setMessage(<Trans>User succefully modified!</Trans>)
          handleCancelClick(id)
          getUsers()
        }
      }
    }
    console.log('OPTS', options)
    sendRequest(options)
  }

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const handleEditClick = (id, section) => () => {
    switch (section) {
      case 'crash':
        setCrashRowModesModel({ ...crashRowModesModel, [id]: { mode: GridRowModes.Edit } })
        break
      case 'road':
        setRoadRowModesModel({ ...roadRowModesModel, [id]: { mode: GridRowModes.Edit } })
        break
      case 'units':
        setUnitsRowModesModel({ ...unitsRowModesModel, [id]: { mode: GridRowModes.Edit } })
        break
      case 'people':
        setPeopleRowModesModel({ ...peopleRowModesModel, [id]: { mode: GridRowModes.Edit } })
        break
      default:
        setUserRowModesModel({ ...userRowModesModel, [id]: { mode: GridRowModes.Edit } })
        break
    }
  }

  const handleSaveClick = (id, section) => () => {
    switch (section) {
      case 'crash':
        setCrashRowModesModel({ ...crashRowModesModel, [id]: { mode: GridRowModes.View } })
        break
      case 'road':
        setRoadRowModesModel({ ...roadRowModesModel, [id]: { mode: GridRowModes.View } })
        break
      case 'units':
        setUnitsRowModesModel({ ...unitsRowModesModel, [id]: { mode: GridRowModes.View } })
        break
      case 'people':
        setPeopleRowModesModel({ ...peopleRowModesModel, [id]: { mode: GridRowModes.View } })
        break
      default:
        setUserRowModesModel({ ...userRowModesModel, [id]: { mode: GridRowModes.View } })
        break
    }
  }

  const handleCancelClick = (id, section) => () => {
    switch (section) {
      case 'crash':
        setCrashRowModesModel({ ...crashRowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        break
      case 'road':
        setRoadRowModesModel({ ...roadRowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        break
      case 'units':
        setUnitsRowModesModel({ ...unitsRowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        break
      case 'people':
        setPeopleRowModesModel({ ...peopleRowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } })
        break
      default:
        setUserRowModesModel({
          ...userRowModesModel
          , [id]: { mode: GridRowModes.View, ignoreModifications: true }
        })
    }
  }

  const processRowUpdate = newRow => {
    const updatedRow = { ...newRow, isNew: false }
    setUserRows(userRows.map(row => (row.id === newRow.id ? updatedRow : row)))
    console.log('UROOOWWW', updatedRow)
    modifyUser(updatedRow)
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel, section) => {
    switch (section) {
      case 'crash':
        setCrashRowModesModel(oldModel => ({ ...oldModel, [section]: newRowModesModel }))
        break
      case 'road':
        setRoadRowModesModel(oldModel => ({ ...oldModel, [section]: newRowModesModel }))
        break
      case 'units':
        setUnitsRowModesModel(oldModel => ({ ...oldModel, [section]: newRowModesModel }))
        break
      case 'people':
        setPeopleRowModesModel(oldModel => ({ ...oldModel, [section]: newRowModesModel }))
        break
      default:
        setUserRowModesModel(newRowModesModel)
    }
  }

  const userColumns = [
    { field: 'id', headerName: 'ID', width: 50, headerClassName: 'overload-header' }
    , { field: 'username', headerName: 'Username', width: 150, headerClassName: 'overload-header' }
    , { field: 'name', headerName: 'First name', editable: true, headerClassName: 'overload-header' }
    , { field: 'surname', headerName: 'Last name', editable: true, headerClassName: 'overload-header' }
    , {
      field             : 'organization'
      , headerName      : 'Organization'
      , headerClassName : 'overload-header'
      , valueGetter     : value => {
        if (value) {
          return organizations.find(o => o.value === value).label
        }
      }
    }
    , {
      field             : 'department'
      , headerName      : 'Department/Unit'
      , headerClassName : 'overload-header'
      , editable        : true
      , width           : 125
      , valueGetter     : (value, row) => row.organization === 1 ? value : null
    }
    , {
      field           : 'departmentAlt'
      , headerName      : 'Hospital/Healthcare facility'
      , headerClassName : 'overload-header'
      , editable        : true
      , width           : 125
      , valueGetter     : (_, row) => row.organization === 2 ? row.department : null
    }
    , { field: 'phone', headerName: 'Phone', headerClassName: 'overload-header' }
    , { field: 'email', headerName: 'Email', headerClassName: 'overload-header' }
    , { field: 'create_date', headerName: 'Date created', headerClassName: 'overload-header' }
    , { field: 'verified', headerName: 'Verified?', type: 'boolean', editable: true, width: 50, headerClassName: 'overload-header' }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : 'Actions'
      , headerClassName : 'overload-header'
      , width           : 100
      , cellClassName   : 'actions'
      , getActions      : ({ id }) => {
        const isInEditMode = userRowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ]
      }
    }
  ]

  // canChange: false
  // column: "report_date"
  // isRequired: true
  // label: "Report date"
  // margin: "dense"
  // options: []
  // permissions: [1, 2, 3]
  // type: "date"
  // unit: ""
  // value: {}

  const configColumns = [
    { field: 'id', headerName: '' }
    , { field: 'section', headerName: 'Section' }
    , {
      field             : 'permissions'
      , headerName      : 'Organizations'
      , headerClassName : 'overload-header'
      , editable        : (_, row) => row.isNew
      , valueGetter     : value => {
        if (value) {
          return value.map(e => organizations.find(o => o.value === e).label)
        }
      }
      , renderCell: params => {
        if (params.value?.length) {
          return (
          <>
            {params.value.map(v => <OrganizationIcon key={randStr()} organization={v} />)}
          </>
          )
        }
      }
    }
    , { field: 'label', headerName: 'Label', headerClassName: 'overload-header', width: 200, editable: (_, row) => row.canChange }
    , { field: 'isRequired', headerName: 'Required?', headerClassName: 'overload-header', type: 'boolean', editable: (_, row) => row.canChange }
    , {
      field             : 'type'
      , headerName      : 'Type'
      , headerClassName : 'overload-header'
      , type            : 'singleSelect'
      , valueOptions    : ['text', 'select', 'date', 'datetime']
      , editable        : (_, row) => row.isNew

    }
    , {
      field           : 'options'
      , headerName      : 'Options'
      , headerClassName : 'overload-header'
      , width           : 400
      , editable        : (_, row) => row.canChange
      , valueGetter     : (value, row) => {
        if (row.type === 'select') return value.map(e => e.label)
        return []
      }
    }
    , { field: 'unit', headerName: 'Unit', headerClassName: 'overload-header', editable: (_, row) => row.canChange }
    , {
      field           : 'value'
      , headerName      : 'Default'
      , headerClassName : 'overload-header'
      , editable        : (_, row) => row.isNew
      , valueGetter     : (value, row) => {
        if (row.type === 'date') return _(msg`date of fill`)
        if (row.type === 'datetime') return _(msg`date & time of fill`)
        if (row.type === 'text') return _(msg`empty field`)
        if (row.type === 'select') return _(msg`Unknown`)
        return value
      }
    }
    , {
      field             : 'actions'
      , type            : 'actions'
      , headerName      : 'Actions'
      , headerClassName : 'overload-header'
      , width           : 100
      , cellClassName   : 'actions'
      , getActions      : obj => {
        const { id, row: { section, canChange } } = obj
        console.log('OBJ', obj, id, section)
        const isInEditMode = section => {
          switch (section) {
            case 'crash':
              return crashRowModesModel[id]?.mode === GridRowModes.Edit
            case 'road':
              return roadRowModesModel[id]?.mode === GridRowModes.Edit
            case 'units':
              return unitsRowModesModel[id]?.mode === GridRowModes.Edit
            case 'people':
              return peopleRowModesModel[id]?.mode === GridRowModes.Edit
            default:
              return userRowModesModel[id]?.mode === GridRowModes.Edit
          }
        }

        if (isInEditMode(section)) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main'
              }}
              onClick={handleSaveClick(id, section)}
            />
            , <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id, section)}
              color="inherit"
            />
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, section)}
            color="inherit"
            disabled={!canChange}
          />
        ]
      }
    }
  ]

  return (
    <Box component="main">
      <DesktopNav subPanelElements={subPanelElements} />
      <Container component="main" maxWidth={'xl'}>
        <CssBaseline />
        <Box
          ml={`${drawerWidth}px`}
          mt={2}
          display='flex'
          flexDirection='column'
          alignItems='center'
          sx={{
            '& .overload-header': {
              backgroundColor   : theme.palette.primary.main
              , color           : theme.palette.primary.contrastText
            }
          }}
        >
        <Typography sx={{ mt: 4 }} variant="h3" align="center">
          <Trans>Users list</Trans>
        </Typography>
        {isLoading
          ? <TableSkel />
          : <DataGrid
              rows={userRows}
              columns={userColumns}
              editMode="row"
              rowModesModel={userRowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={updatedRow => {
                modifyUser(updatedRow)
              }}
              initialState={{ pagination: { paginationModel } }}
              pageSizeOptions={[5, 10]}
              sx={{
                mt                        : 4
                , border                  : 0
                , '& .MuiDataGrid-filler' : {
                  backgroundColor: theme.palette.primary.main
                }
              }}
              slots={{ toolbar: FakeToolbar }}
            />
          }
          {error && <Alert sx={{ mt: 2 }} severity="error">
            <Trans>There was an error: {error}.</Trans>
          </Alert>}
          {message && <Alert sx={{ mt: 2 }} severity='success'>
            {message}
          </Alert>}
        <Typography sx={{ mt: 4 }} variant="h3" align="center">
          <Trans>Fields configuration</Trans>
        </Typography>
        <Box mt={3} display='flex' justifyContent='space-evenly' width='100%'>
          {organizations
            .filter(org => org.value !== 0)
            .map(org => (
              <Box key={org.value} display='flex'>
                <OrganizationIcon organization={org.label} />
                <Typography ml={1}>{org.label}</Typography>
              </Box>
            ))}
        </Box>
        <Box width='100%'>
          <Typography sx={{ mt: 4 }} variant="h4" align="center">
            <Trans>CRASH</Trans>
          </Typography>
          {isLoading
            ? <TableSkel />
            : <DataGrid
                rows={crashRows}
                columns={configColumns}
                editMode="row"
                rowModesModel={crashRowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{
                  pagination : { paginationModel }
                  , columns    : { columnVisibilityModel: { id: false, section: false } }
                }}
                pageSizeOptions={[5, 10]}
                sx={{ mt: 1, border: 0 }}
                slots={{
                  toolbar: EditToolbar
                }}
                slotProps={{
                  toolbar: { section: 'crash', setRows: setCrashRows, setRowModesModel: setCrashRowModesModel }
                }}
              />
            }
            {error && <Alert sx={{ mt: 2 }} severity="error">
              <Trans>There was an error: {error}.</Trans>
            </Alert>}
            {message && <Alert sx={{ mt: 2 }} severity='success'>
              {message}
            </Alert>}
        </Box>
        <Box width='100%'>
          <Typography sx={{ mt: 4 }} variant="h4" align="center">
            <Trans>ROAD</Trans>
          </Typography>
          {isLoading
            ? <TableSkel />
            : <DataGrid
                rows={roadRows}
                columns={configColumns}
                editMode="row"
                rowModesModel={roadRowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{
                  pagination : { paginationModel }
                  , columns    : { columnVisibilityModel: { id: false, section: false } }
                }}
                pageSizeOptions={[5, 10]}
                sx={{ mt: 1, border: 0 }}
                slots={{
                  toolbar: EditToolbar
                }}
                slotProps={{
                  toolbar: { section: 'road', setRows: setRoadRows, setRowModesModel: setRoadRowModesModel }
                }}
              />
            }
            {error && <Alert sx={{ mt: 2 }} severity="error">
              <Trans>There was an error: {error}.</Trans>
            </Alert>}
            {message && <Alert sx={{ mt: 2 }} severity='success'>
              {message}
            </Alert>}
        </Box>
        <Box width='100%'>
          <Typography sx={{ mt: 4 }} variant="h4" align="center">
            <Trans>TRAFFIC UNITS</Trans>
          </Typography>
          {isLoading
            ? <TableSkel />
            : <DataGrid
                rows={unitsRows}
                columns={configColumns}
                editMode="row"
                rowModesModel={unitsRowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{
                  pagination : { paginationModel }
                  , columns    : { columnVisibilityModel: { id: false, section: false } }
                }}
                pageSizeOptions={[5, 10]}
                sx={{ mt: 1, border: 0 }}
                slots={{
                  toolbar: EditToolbar
                }}
                slotProps={{
                  toolbar: { section: 'units', setRows: setUnitsRows, setRowModesModel: setUnitsRowModesModel }
                }}
              />
            }
            {error && <Alert sx={{ mt: 2 }} severity="error">
              <Trans>There was an error: {error}.</Trans>
            </Alert>}
            {message && <Alert sx={{ mt: 2 }} severity='success'>
              {message}
            </Alert>}
        </Box>
        <Box width='100%'>
          <Typography sx={{ mt: 4 }} variant="h4" align="center">
            <Trans>PEOPLE</Trans>
          </Typography>
          {isLoading
            ? <TableSkel />
            : <DataGrid
                rows={peopleRows}
                columns={configColumns}
                editMode="row"
                rowModesModel={peopleRowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                initialState={{
                  pagination : { paginationModel }
                  , columns    : { columnVisibilityModel: { id: false, section: false } }
                }}
                pageSizeOptions={[5, 10]}
                sx={{ mt: 1, border: 0 }}
                slots={{
                  toolbar: EditToolbar
                }}
                slotProps={{
                  toolbar: { section: 'people', setRows: setPeopleRows, setRowModesModel: setPeopleRowModesModel }
                }}
              />
            }
            {error && <Alert sx={{ mt: 2 }} severity="error">
              <Trans>There was an error: {error}.</Trans>
            </Alert>}
            {message && <Alert sx={{ mt: 2 }} severity='success'>
              {message}
            </Alert>}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
