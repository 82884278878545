import { Routes, Route } from 'react-router-dom'
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material'
import useWindowSize from './hooks/useWindowSize'
import { InitProvider } from './context/initContext'
import Login from './pages/Login'
import Home from './pages/Home'
import Signup from './pages/Signup'
import User from './pages/User'
import Admin from './pages/Admin'

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#10171f'
    }
  }
  , mixins: {
    drawerWidth       : 256
    , formDrawerWidth : 320
    , toolbar         : {
      minHeight: 64
    }
  }
})

export default function App () {
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('sm'))
  const windowSize = useWindowSize()
  console.log('WS', windowSize)

  return (
    <InitProvider>
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<Login isMobile={isMobile} />} />
          <Route path='/home' element={<Home isMobile={isMobile} />} />
          <Route path='/signup' element={<Signup isMobile={isMobile} />} />
          <Route path='/user' element={<User isMobile={isMobile} />} />
          <Route path='/admin' element={<Admin isMobile={isMobile} />} />
        </Routes>
      </ThemeProvider>
    </InitProvider>
  )
}
