import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Trans, t, msg } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import {
  Alert
  , Avatar
  , Box
  , Collapse
  , Dialog
  , DialogTitle
  , Divider
  , List
  , ListItem
  , ListItemAvatar
  , ListItemButton
  , ListItemText
  , Slide
  , Snackbar
  , Toolbar
  , useTheme
} from '@mui/material'
// import { AddLocationOutlined, Close, EditLocationOutlined, Logout } from '@mui/icons-material'
import { Close } from '@mui/icons-material'
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt'
// import EditLocationIcon from '@mui/icons-material/EditLocation'
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp'
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
// import LocalHospitalIcon from '@mui/icons-material/LocalHospital'
// import LocalPoliceIcon from '@mui/icons-material/LocalPolice'
// import SecurityIcon from '@mui/icons-material/Security'
// import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined'
// import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined'
// import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined'
// import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import DesktopNav from '../components/desktop/DesktopNav'
import NaviTreeView from '../components/forms/NaviTree'
import MobileTopNav from '../components/mobile/MobileTopNav'
import MobileBottomNav from '../components/mobile/MobileBottomNav'
import useHttp from '../hooks/useHttp'
import useLogout from '../hooks/useLogout'
import FormContainer from '../components/forms/FormContainer'
import MobileLayersPanel from '../components/mobile/MobileLayers'
import Legend from '../components/Legend'
import FullPageProgress from '../components/FullPageProgress'
import MapLibre from '../components/maps/MapLibre'
import moment from 'moment'

// eslint-disable-next-line no-restricted-globals
const crashUrl = new URL('/api/crash/add', location)
// eslint-disable-next-line no-restricted-globals
const allCrashesUrl = new URL('/api/crash/all', location)
// eslint-disable-next-line no-restricted-globals
const draftUrl = new URL('/api/draft', location)
// eslint-disable-next-line no-restricted-globals
const allDraftsUrl = new URL('/api/drafts', location)

// This is used
// eslint-disable-next-line no-unused-vars
function SlideTransition (props) {
  return <Slide {...props} direction="up" />
}

function composeFormList (formObj) {
  let formList = []
  Object.keys(formObj).forEach(key => {
    if (Array.isArray(formObj[key])) {
      formObj[key].forEach((item, i) => {
        formList = [...formList, `${key}.${i}`]
        Object.keys(item).forEach(elem => {
          if (Array.isArray(item[elem])) {
            item[elem].forEach((_, j) => {
              formList = [...formList, `${key}.${i}.${elem}.${j}`]
            })
          }
        })
      })
    } else formList = [...formList, key]
  })

  return formList
}

export default function Home ({ isMobile }) {
  const location = useLocation()
  const token = location.state?.token || null
  const init = useMemo(() => location.state?.init || {}, [location.state?.init])
  const logout = useLogout()
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const { _ } = useLingui()
  const methods = useForm({
    defaultValues: useMemo(elements => elements, [])
  })
  const unitsFieldArrayMethods = useFieldArray({ control: methods.control, name: 'units' })
  const peopleFieldArrayMethods = useFieldArray({ control: methods.control, name: 'people' })
  const { isLoading, error, sendRequest } = useHttp()
  const [user, setUser] = useState({})
  const [totCrashes, setTotCrashes] = useState({ total: 0, fatal: 0 })
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isPositioning, setIsPositioning] = useState(false)
  const [isPositionSet, setIsPositionSet] = useState(false)
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [isDraftsMenuOpen, setIsDraftsMenuOpen] = useState(false)
  const [allDrafts, setAllDrafts] = useState([])
  const [allCrashes, setAllCrashes] = useState([])
  const [showCrashes, setShowCrashes] = useState(false)
  const [message, setMessage] = useState('')
  const [openMobileLayers, setOpenMobileLayers] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  const [formNumber, setFormNumber] = useState(0)
  const [formModel, setFormModel] = useState({})
  const [formList, setFormsList] = useState([])
  const [defaults, setDefaults] = useState({})
  const submitRef = useRef()

  // Fetch of init
  useEffect(() => {
    if (!token) return logout()
    const { configuration, defaults, user, totalCrashes } = init
    setUser(user)
    setTotCrashes(totalCrashes)
    setFormModel(configuration)
    const initForms = Object.keys(configuration)
      .filter(key => configuration[key].hierarchy === 0)
      .sort((a, b) => configuration[a].position - configuration[b].position)

    setFormsList(() => initForms)
    console.log('DEFAULTS', defaults)
    setDefaults(defaults)
    let initFormObj = {}
    initForms.forEach(form => (initFormObj = { ...initFormObj, [form]: defaults[form] }))
    // This sets the defaults of the form
    methods.reset(initFormObj) // {defaults.crash, road: defaults.road }
    // }
    // })

    // const initFormsStruct = JSON.parse(init())
    // setFormModel(initFormsStruct)
    // const initForms = Object.keys(initFormsStruct)
    //   .filter(key => initFormsStruct[key].hierarchy === 0)
    //   .sort((a, b) => initFormsStruct[a].position - initFormsStruct[b].position)

    // setFormsList(() => initForms)
    // console.log(defaults)
    // let initFormObj = {}
    // initForms.forEach(form => (initFormObj = { ...initFormObj, [form]: defaults[form] }))
    // This sets the defaults of the form
    // methods.reset(initFormObj) // {defaults.crash, road: defaults.road }
  }, [token, init, logout, methods])

  const getAllDrafts = useCallback(() => {
    if (!token) return logout()
    if (!isDialogOpen) return
    sendRequest({
      token
      , url           : allDraftsUrl
      , manageResData : res => {
        setAllDrafts(res.drafts)
      }
    })
  }, [token, isDialogOpen, sendRequest, logout])

  const getDraft = useCallback(draftId => {
    if (!token) return logout()
    const getDraftUrl = new URL(draftUrl)
    getDraftUrl.pathname += `/${draftId}`
    sendRequest({
      token
      , url           : getDraftUrl
      , manageResData : res => {
        delete res.data.isDraft
        delete res.data.isFinal
        const draftFormsList = composeFormList(res.data)
        setFormsList(draftFormsList)
        methods.reset(res.data) // Resets form
        closeDialog()
        setIsFormOpen(true)
      }
    })
  }, [token, sendRequest, logout, methods])

  // Add crash flow controls
  const openDialog = () => { setIsDialogOpen(true) }
  const closeDialog = () => { setIsDialogOpen(false) }

  const handlePositioningState = state => {
    setIsPositioning(state)
  }

  const handleFormOpen = state => {
    setIsFormOpen(state)
  }

  const setCoordinates = coords => {
    methods.setValue('crash.Latitude', coords.lat)
    methods.setValue('crash.Longitude', coords.lng)
    setIsPositionSet(true)
  }

  // Map overlays controls
  const toggleMobileLayers = bool => setOpenMobileLayers(typeof bool !== 'boolean' ? !openMobileLayers : bool)

  const handleLegendVisibility = setShowLegend

  const getCrashes = () => {
    sendRequest({
      token
      , url           : allCrashesUrl
      , httpHeaders   : { Authorization: `Bearer ${token}` }
      , manageResData : res => setAllCrashes(res.data)
    })
  }

  // Form navigation controls
  const handleSetFormNumber = number => {
    let newFormNumber = 0
    if (number > 1) return setFormNumber(number)
    if (formNumber + number >= 0) {
      newFormNumber = formNumber + number
    }
    setFormNumber(newFormNumber)
  }

  const handleSetFormNumberFromList = formName => {
    setFormNumber(formList.indexOf(formName))
  }

  const getFormType = useCallback(number => {
    let type
    if ((user?.organization === 1 && number < 3) || number === 0) {
      type = Object.keys(formModel)
        .sort((a, b) => formModel[a].position - formModel[b].position)[number]
    } else {
      type = formList[number]?.split('.').slice(-2, -1)[0]
    }
    return type
  }, [formList, formModel, user.organization])

  const getFormDisplayName = useCallback(number => {
    return formModel[getFormType(number)].displayName
  }, [formModel, getFormType])

  const filterObject = object => {
    const { isDraft, isFinal, ...rest } = object
    return rest
  }

  // This appends a new instance of repeated form
  const handleAppend = (formType, nestElem, append) => {
    console.log('FORM LIST', formList)
    console.log(defaults.units, defaults.people)
    if (formType === 'units') {
      const lastIndex = unitsFieldArrayMethods.fields.length
      unitsFieldArrayMethods.append(defaults.units.map(elem => ({ ...elem, 'Traffic unit id': lastIndex + 1 })))
      setFormsList(old => [...old, `${formType}.${lastIndex}`])
      setFormNumber(formList.length)
      return
    }
    if (formType === 'people') {
      const lastIndex = peopleFieldArrayMethods.fields.length
      peopleFieldArrayMethods.append(defaults.people.map(elem => ({ ...elem, 'Person id': lastIndex + 1 })))
      setFormsList(old => [...old, `${formType}.${lastIndex}`])
      setFormNumber(formList.length)
      return
    }
    if (formType === 'unitPeople') {
      console.log(nestElem, methods.getValues())
      nestElem = nestElem.split('.').slice(0, 2).join('.')
      const nestIndex = methods.getValues(nestElem)?.people?.length || 0
      const newNestedName = `${nestElem}.people.${nestIndex}`
      append(defaults.people.map(elem => ({ ...elem, 'Person id': nestIndex + 1, 'Traffic unit id': Number.parseInt(nestElem.split('.')[1]) + 1 })))
      setFormsList(old => [...old, newNestedName])
      setFormNumber(formList.length)
    }
  }

  // Get form data and post it
  const handleCrashSubmit = data => {
    console.log(data)
    let url
    if (data.isDraft) {
      url = draftUrl
    } else {
      url = crashUrl
    }

    const newData = { ...data, crash_id: data.crash['Crash id'] }
    sendRequest({
      token
      , url
      , httpMethod    : 'POST'
      , httpBody      : newData
      , manageResData : res => {
        if (data.isFinal) {
          handleFormOpen(false)
          setIsPositioning(false)
          setIsPositionSet(false)
          methods.reset()
          setFormNumber(0)
          setMessage(t`Crash saved ${data.isDraft ? 'as a draft' : `with id ${res.crash_id}`}`)
        }
      }
    })
  }

  const subPanelElements = [
    {
      text       : t`Show crashes`
      , icon     : null
      , type     : 'switch'
      , name     : 'crashes'
      , onMobile : true
      , action   : async e => {
        const isChecked = e.target.checked
        if (isChecked) await getCrashes()
        setShowCrashes(isChecked)
      }
    }
    , {
      text       : t`Show legend`
      , icon     : null
      , type     : 'switch'
      , name     : 'legend'
      , onMobile : true
      , action   : e => {
        const isChecked = e.target.checked
        handleLegendVisibility(isChecked)
      }
    }
    , {
      text       : t`Add crash`
      , icon     : isPositioning ? <Close color='primary' /> : <AddLocationAltIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : isPositioning
        ? () => {
            setIsPositionSet(false)
            setIsPositioning(false)
            setIsFormOpen(false)
            methods.reset() // Resets form
            setFormNumber(0)
          }
        : openDialog
    }
    , {
      text       : t`Logout`
      , icon     : <LogoutSharpIcon color='primary' />
      , type     : 'button'
      , onMobile : false
      , action   : logout
    }
  ]

  return (
    <Box component="main">
      {isMobile
        ? <>
            <MobileTopNav />
            <Toolbar />
        </>
        : <DesktopNav subPanelElements={subPanelElements} />}
      <Box ml={isMobile ? 0 : `${drawerWidth}px`}>
        {isFormOpen &&
          <FormProvider {...methods} {...user}>
            <FormContainer
              token={token}
              open={isFormOpen}
              isLoading={isLoading}
              isMobile={isMobile}
              formModel={formModel}
              formList={formList}
              form={{
                number        : formNumber
                , type        : getFormType(formNumber)
                , displayName : getFormDisplayName(formNumber)
              }}
              control={methods.control}
              submitRef={submitRef}
              handleFormOpen={handleFormOpen}
              handleSetFormNumber={handleSetFormNumber}
              handleCrashSubmit={handleCrashSubmit}
              handleAppend={handleAppend}
              handlePositioningState={handlePositioningState}
            >
            </FormContainer>
            <NaviTreeView
              data={filterObject(methods.getValues())}
              handleSetFormNumber={handleSetFormNumberFromList}
            />
          </FormProvider>}
        <Snackbar
          open={!!message}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent='SlideTransition'
          onClose={(_, reason) => {
            if (reason === 'clickaway') {
              return
            }
            setMessage('')
          }}>
          <Alert variant='filled' severity="success" sx={{ width: '100%' }}>{message}</Alert>
        </Snackbar>
        <Snackbar
          open={!!error}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          TransitionComponent='SlideTransition'
          onClose={(_, reason) => {
            if (reason === 'clickaway') {
              return
            }
            setMessage('')
          }}>
          <Alert variant='filled' severity='error' sx={{ width: '100%' }}>{error}</Alert>
        </Snackbar>
        <MapLibre
          isMobile={isMobile}
          isLoading={isLoading}
          isPositioning={isPositioning}
          isFormOpen={isFormOpen}
          handlePositioningState={handlePositioningState}
          handleFormOpen={handleFormOpen}
          setCoordinates={setCoordinates}
          isPositionSet={isPositionSet}
          showCrashes={showCrashes}
          allCrashes={allCrashes}
          crashesSummary={totCrashes}
        />
        <Dialog open={isDialogOpen} onClose={closeDialog}>
          <DialogTitle>
            <Trans>What do you want to do?</Trans>
          </DialogTitle>
            <List sx={{ pt: 0 }}>
              <ListItem disableGutters>
              <ListItemButton onClick={() => {
                setIsDraftsMenuOpen(true)
                getAllDrafts()
              }}>
                  <ListItemAvatar>
                    <Avatar>
                      <EditLocationAltIcon />
                    </Avatar>
                  </ListItemAvatar>
                <ListItemText primary={_(msg` Edit a crash from drafts`)} />
                </ListItemButton>
              </ListItem>
              <Collapse in={isDraftsMenuOpen} timeout="auto" unmountOnExit>
                <Divider />
                  <List component="div" disablePadding>
                    {allDrafts.length
                      ? allDrafts.map(draft => {
                        const label = draft.replace(/^\d_/, '').replace('p', '+')
                        return (
                          <ListItemButton
                            key={draft}
                            sx={{ pl: 6 }}
                            onClick={() => getDraft(draft)}
                          >
                            <ListItemText primary={moment(label, 'YYYYMMDDThhmmssSS').toLocaleString()} />
                          </ListItemButton>
                        )
                      })
                      : <ListItemText sx={{ pl: 6 }} primary={t`No drafts saved`} />
                    }
                  </List>
                <Divider />
              </Collapse>
              <ListItem disableGutters>
                <ListItemButton
                  onClick={() => {
                    closeDialog()
                    handlePositioningState(true)
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddLocationAltIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={_(msg`Add new crash on map`)} />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters>
                <ListItemButton
                  onClick={() => {
                    closeDialog()
                    handleFormOpen(true)
                  }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <AddLocationIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={_(msg`Add new crash at your current location`)} />
                </ListItemButton>
              </ListItem>
            </List>
        </Dialog>
      </Box>
      {isMobile &&
        <MobileBottomNav
          token={token}
          user={user}
          openDialog={openDialog}
          handleFormOpen={handleFormOpen}
          isPositioning={isPositioning}
          formReset={methods.reset}
          handlePositioningState={handlePositioningState}
          openMobileLayers={openMobileLayers}
          toggleMobileLayers ={toggleMobileLayers}
        />}
      {isMobile && <MobileLayersPanel open={openMobileLayers} subPanelElements={subPanelElements} />}
      <FullPageProgress isOpen={isLoading} isMobile={isMobile} isFormOpen={isFormOpen} />
      {showLegend && <Legend isMobile={isMobile} />}
    </Box>
  )
}
