import { useCallback, useEffect, useRef, useState } from 'react'
import maplibregl, { Popup, Marker, AttributionControl } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { Box, Chip, ClickAwayListener, Fade, IconButton, MenuItem, MenuList, Paper, Popper, Slide, useTheme } from '@mui/material'
// import LayersTwoToneIcon from '@mui/icons-material/LayersTwoTone'
import LayersIcon from '@mui/icons-material/Layers'
import GeneralInfo from '../GeneralInfo'
import { makePin } from './Pin'
import { SEVERITIES } from '../../constants'
import Copyright from '../Copyright'
import useWindowSize from '../../hooks/useWindowSize'
// import './markers.css'

const CENTER = [
  Number.parseFloat(process.env.REACT_APP_CENTER_LON)
  , Number.parseFloat(process.env.REACT_APP_CENTER_LAT)
] // LON, LAT Rome - This should be capital of the state
const ZOOM = 13
const PINSCALE = 0.75
const OFFSET = [0, -10]

export default function MapLibre ({
  isMobile
  , isLoading
  , isPositioning
  , isPositionSet
  , handlePositioningState
  , isFormOpen
  , handleFormOpen
  , setCoordinates
  , showCrashes
  , allCrashes
  , crashesSummary
}) {
  if (process.env.REACT_APP_API_KEY == null) {
    throw new Error('You have to configure env REACT_APP_API_KEY, see README')
  }

  const theme = useTheme()
  const barHeight = theme.mixins.toolbar.minHeight
  const drawerWidth = theme.mixins.drawerWidth
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const mapContainerElement = useRef()
  const [map, setMap] = useState({})
  const [markers, setMarkers] = useState([])

  const options = [
    { name: 'Map', style: 'openstreetmap' }
    , { name: 'Urban', style: 'streets' }
    , { name: 'Satellite', style: 'satellite' }
    , { name: 'Hybrid', style: 'hybrid' }
  ]

  const handleMapStyleChange = index => {
    return `https://api.maptiler.com/maps/${options[index].style}/style.json?key=${process.env.REACT_APP_API_KEY}`
  }

  const StyleControl = () => {
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleClick = event => {
      setAnchorEl(event.currentTarget)
      setOpen(prevOpen => !prevOpen)
    }

    const canBeOpen = open && Boolean(anchorEl)
    const id = canBeOpen ? 'transition-popper' : undefined

    return (
    <>
        {/* <Button onClick={handleClick}>{options[selectedIndex]}</Button> */}
      <IconButton
        sx={{
          '&:hover': {
            backgroundColor: 'rgb(242 242 242)'
          }
          , WebkitTapHighlightColor : 'rgb(0 0 0/0)'
          , WebkitTextSizeAdjust    : '100%'
          , WebkitFontSmoothing     : 'antialiased'
          , color                   : 'rgba(0, 0, 0, 0.87)'
          , pointerEvents           : 'auto'
          , background              : '#fff'
          , borderRadius            : '4px'
          , float                   : 'right'
          , margin                  : '9.25rem 10px 0 0'
          , boxShadow               : '0 0 0 2px rgba(0,0,0,.1)'
          , padding                 : '3px'
          , fontSize                : '1.45rem'
          , lineHeight              : '20px'
        }}
        aria-describedby={id}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select map layer"
        aria-haspopup="menu"
        disableRipple
        onClick={handleClick}
      >
        <LayersIcon fontSize='inherit' />
      </IconButton>
      <Popper
        id={id}
        sx={{
          zIndex: 1
        }}
        open={open}
        anchorEl={anchorEl}
        role={undefined}
        transition
        placement='left'
        disablePortal
        modifiers={[
          {
            name    : 'offset'
            , options : {
              offset: [0, 10]
            }
          }
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: 'right'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList id="popper-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.style}
                      selected={index === selectedIndex}
                      onClick={() => {
                        setSelectedIndex(index)
                        map.setStyle(handleMapStyleChange(index))
                        setOpen(false)
                      }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
    )
  }

  useEffect(() => {
    const glMap = new maplibregl.Map({
      container          : mapContainerElement.current
      , style              : handleMapStyleChange(0)
      , center             : CENTER
      , zoom               : ZOOM
      , attributionControl : false
    })

    glMap.addControl(new maplibregl.NavigationControl(), 'top-right')
    glMap.addControl(new AttributionControl({ compact: isMobile }))
    // Initialize the geolocate control.
    const geolocate = new maplibregl.GeolocateControl({
      showAccuracyCircle   : isMobile
      , trackUserLocation  : isMobile
      , positionOptions    : {
        enableHighAccuracy: true
      }
    })

    // Add the control to the map.
    glMap.addControl(geolocate)
    // Set an event listener that fires
    // when a geolocate event occurs.
    geolocate.on('geolocate', event => {
      console.log('A geolocate event has occurred.', event)
    })

    glMap.on('load', () => {
      const attribution = document.getElementsByTagName('details')[0]
      if (attribution.classList.contains('maplibregl-compact-show')) {
        attribution.classList.remove('maplibregl-compact-show')
      }
      geolocate.trigger()
    })

    setMap(glMap)

    return () => {
      glMap.remove()
      setMap({})
    }
  }, [])

  useEffect(() => {
    if (showCrashes) {
      allCrashes.forEach(crash => {
        const fill = SEVERITIES().find(e => e.value === crash.severity).fill
        const elem = makePin(document, fill, PINSCALE)
        const marker = new Marker({ element: elem })
          .setLngLat([crash.lon, crash.lat])
          .setPopup(new Popup().setHTML(`<h3>${crash.id}</h3>`))
          .addTo(map)

        setMarkers(old => [...old, { id: crash.id, marker }])
      })
    } else if (markers?.length) {
      console.log(markers)
      console.log(markers.length)
      markers.forEach(elem => {
        elem.marker.remove()
        setMarkers(old => old.filter(e => e.id !== elem.id))
      })
    }

    return () => {
      markers.forEach(elem => {
        elem.marker.remove()
        setMarkers(old => old.filter(e => e.id !== elem.id))
      })
    }
  }, [showCrashes])

  const NewCrashSelector = ({ isMobile, map, handlePositioningState }) => {
    const windowSize = useWindowSize()
    const cH = useRef(null)
    const cV = useRef(null)

    const transitionDuration = {
      enter   : theme.transitions.duration.enteringScreen
      , exit  : theme.transitions.duration.leavingScreen
    }

    const styles = {
      cH: {
        width  : '100%'
        , height : '1px'
      }
      , cV: {
        height : '100%'
        , width  : '1px'
        , top    : 0
      }
      , hair: {
        position        : 'absolute'
        , backgroundColor : '#000'
        , pointerEvents   : 'none'
        , zIndex          : theme.zIndex.drawer - 1
      }
    }

    const onclick = useCallback(e => {
      console.log('LNGLAT', e.lngLat)
      const container = mapContainerElement.current
      const newMarkers = container.getElementsByClassName('new-markers')
      Array.from(newMarkers).forEach(marker => marker.remove())
      const fill = SEVERITIES()[0].fill
      const element = makePin(document, fill, PINSCALE, 'new-markers')
      new Marker({ element, offset: OFFSET }) // , draggable: true
        .setLngLat([e.lngLat.lng, e.lngLat.lat])
        .addTo(map)

      map.setCenter(e.lngLat)
      setCoordinates(e.lngLat)
      handleFormOpen(true)
      // handlePositioningState(false)
    }, [map])

    useEffect(() => {
      const container = mapContainerElement.current
      if (!isMobile && isPositioning) {
        const ch = cH.current
        const cv = cV.current
        container.onmousemove = e => {
          ch.style.top = `${e.clientY}px`
          cv.style.left = isFormOpen
            ? `${e.clientX - drawerWidth - formDrawerWidth}px`
            : `${e.clientX - drawerWidth}px`
        }
      }

      if (isPositioning) map.on('click', onclick)
    }, [isMobile, map, onclick])

    useEffect(() => {
      const cc = map.getContainer()
      const els = cc.getElementsByClassName('new-markers')
      console.log('MM', els)
    }, [isPositioning])

    return (
      <>
        <Slide
          in={isPositioning && !isPositionSet}
          timeout={transitionDuration}
          // style={{
          //   transitionDelay: `${isPositioning ? transitionDuration.exit : 0}ms`
          // }}
          unmountOnExit
        >
          <Box
            position='absolute'
            top={isMobile ? '1rem' : '3rem'}
            // top={isMobile ? `calc(${barHeight}px + 1rem)` : '3rem'}
            width='100%'
            display='flex'
            flexDirection='column'
            alignItems='center'
            zIndex={isMobile ? theme.zIndex.drawer - 1 : 99999}
          >
            <Chip label='Please select the new crash location' color='error' />
          </Box>
        </Slide>
        <Box position='absolute' top={0} left={0} width={windowSize.width - drawerWidth} height={windowSize.height}>
          <div ref={cH} style={{ ...styles.cH, ...styles.hair }}></div>
          <div ref={cV} style={{ ...styles.cV, ...styles.hair }}></div>
        </Box>
      </>
    )
  }

  useEffect(() => {
    const container = mapContainerElement.current
    /* This changes the cursor style during positioning */
    const mapElem = [...container.children]
      .find(ch => ch.className.includes('canvas'))
    if (isPositioning) {
      mapElem.style.cursor = 'none'
    } else {
      mapElem.style.cursor = 'grab'
    }
  }, [isPositioning])

  return (
    <Box
      height={isMobile ? `calc(100vh - ${barHeight * 2}px)` : '100vh'}
      ml={isFormOpen && !isMobile ? `${formDrawerWidth}px` : 0}
    >
      <Box id='map-cont' ref={mapContainerElement} width='100%' height='100%'>
        {isPositioning &&
          <NewCrashSelector
            isMobile={isMobile}
            handlePositioningState={handlePositioningState}
            map={map}
          />}
        <GeneralInfo crashesSummary={crashesSummary} />
        <Copyright bgcolor='hsla(0, 0%, 100%, .5)' left={0} bottom={0} />
        <StyleControl />
      </Box>
    </Box>
  )
}
