import { useCallback, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material'

import { RichTreeView } from '@mui/x-tree-view/RichTreeView'
import { useTreeItem2 } from '@mui/x-tree-view/useTreeItem2'
import {
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2GroupTransition,
  TreeItem2Label,
  TreeItem2Root,
  TreeItem2Checkbox
} from '@mui/x-tree-view/TreeItem2'
import { useLocation } from 'react-router-dom'

export default function NaviTreeView ({ data, handleSetFormNumber }) {
  console.log('hjhjhjh', data)
  const theme = useTheme()
  const drawerWidth = theme.mixins.drawerWidth
  const formDrawerWidth = theme.mixins.formDrawerWidth
  const [items, setItems] = useState([])
  const [expandedItems, setExpandedItems] = useState([])
  const location = useLocation()
  const { init } = location.state

  const unitsOptions = init.configuration.units?.elements
    .find(el => el.label === 'Type').options

  const composeTreeView = useCallback(object => {
    return Object.entries(object).map(([key, value]) => {
      if (Array.isArray(value)) {
        if (key === 'units') {
          return {
            id       : key
            , label    : key
            , children : value.map((v, i) => ({
              id       : `${key}.${i}`
              , label    : `${unitsOptions.find(uo => uo.value === v.Type).label}-${v['Traffic unit id' || 'unit id']}`
              , children : v.people.length
                ? v.people.map((p, j) => ({ id: `${key}.${i}.people.${j}`, label: `${p['Person id']}-${p.Surname}` }))
                : []
            }))
          }
        }
        if (key === 'people') {
          return {
            id         : key
            , label    : key
            , children : value.map((v, i) => ({
              id         : `people.${i}`
              , label    : `${v['Person id']}-${v.Surname ? v.Surname : 'person'}`
            }))
          }
        }
      } else return { id: key, label: key, children: [] }
    })
  }, [unitsOptions])

  const transform = useCallback(object => {
    return Object
      .keys(object)
      .map(key => key === 'children'
        ? transform(object[key])
        : object[key].id
      )
  }, [])

  useEffect(() => {
    console.log('DATA', data)
    console.log('TT', composeTreeView(data))
    console.log('TTTT', transform(composeTreeView(data)))
    setItems(composeTreeView(data))
    setExpandedItems(transform(composeTreeView(data)))
  }, [data, composeTreeView, transform])

  return (
    <Box
      zIndex={theme.zIndex.drawer}
      position='absolute'
      left={`${drawerWidth + formDrawerWidth}px`}
      top={0}
    >
      <Paper
        variant='outlined'
        sx={{ borderWidth: '1px 1px 1px 0', borderRadius: '0 0 2px 0' }}>
        <RichTreeView
          items={items}
          expandedItems={expandedItems}
          onItemClick={(_, id) => {
            console.log('NAVI', id)
            if (id === 'people' || id === 'units') return
            return handleSetFormNumber(id)
          }}
          slotProps={{
            collapseIcon: { style: { display: 'none' } }
          }}
          sx={{ pr: '12px' }}
        />
      </Paper>
    </Box>
  )
}

// const data =
// {
//   crash: {
//     'City/Location'        : ''
//     , 'Crash date'         : '2024-09-23T15:19:31.373Z'
//     , 'Crash id'           : '20240923_00001'
//     , 'Crash severity'     : 2
//     , 'Crash type'         : 2
//     , Latitude             : 41.73911023831792
//     , 'Light conditions'   : 0
//     , Longitude            : 13.414205299382246
//     , Region               : 4
//     , 'Report date'        : '2024-09-23T15:19:31.373Z'
//     , 'Weather conditions' : 0
//   }
//   , road: {
//     'Area type'                                       : 0
//     , 'Carriageway type'                              : 0
//     , 'Functional class - 1st road'                   : 0
//     , 'Functional class - 2nd road (if intersection)' : 0
//     , 'Number of lanes'                               : 0
//     , Road                                            : 'tumbldldld'
//     , 'Road type'                                     : 0
//     , 'Speed limit'                                   : 6
//     , 'Surface conditions and status'                 : 0
//     , 'Type of intersection'                          : 0
//     , 'Type of intersection control'                  : 0
//     , 'Work zone'                                     : 0
//   }
//   , units: [
//     {
//       'Hit & run'                      : 0
//       , Insurance                      : 0
//       , Overloading                    : 0
//       , 'Technical inspection'         : 0
//       , 'Traffic unit manoeuvre'       : 0
//       , Trailer                        : 0
//       , Type                           : 1
//       , 'Vehicle registration country' : 0
//       , 'Vehicle registration year'    : ''
//       , 'unit id'                      : '01'
//       , 'Traffic unit id'              : 1
//       , people                         : [
//         {
//           'Alcohol use'                       : 0
//           , 'Date of admission'               : ''
//           , 'Date of birth'                   : ''
//           , 'Distracted by electronic device' : 0
//           , 'Driving license issue date'      : ''
//           , 'Driving license validity'        : 0
//           , Gender                            : 1
//           , Name                              : 'aaa'
//           , Nationality                       : 0
//           , 'Person id'                       : 1
//           , 'Road user type'                  : 0
//           , 'Safety equipment use'            : 0
//           , 'Seating position in/on vehicle'  : 1
//           , Surname                           : 'bbbb'
//           , 'Traffic unit id'                 : 1
//         }
//       ]
//     }
//   ]
// }

// const items = [
//   {
//     id       : 'crash'
//     , label    : 'Crash'
//     , children : [{ id: 'info', label: 'info' }
//       , { id: 'road', label: 'Road' }
//       , {
//         id       : 'units'
//         , label    : 'Traffic units'
//         , children : [
//           {
//             id       : 'unit.0'
//             , label    : '01 - Car'
//             , children : [
//               { id: 'unit.0.people.0', label: '01 - Surname' }
//               , { id: 'unit.0.people.1', label: '02 - Surname_2' }
//             ]
//           }
//           , {
//             id       : 'unit.1'
//             , label    : '02 - Car'
//             , children : [
//               { id: 'unit.1.people.0', label: '03 - Surname_3' }
//             ]
//           }
//           , {
//             id       : 'unit.2'
//             , label    : '03 - Bike'
//             , children : [
//               { id: 'unit.2.people.0', label: '04 - Surname_4' }
//             ]
//           }
//         ]
//       }
//     ]
//   }
// ]
