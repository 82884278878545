import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useLingui } from '@lingui/react'
import { Trans, msg } from '@lingui/macro'
import {
  Alert
  , Box
  , Button
  , IconButton
  , InputAdornment
  , LinearProgress
  , MenuItem
  , TextField
} from '@mui/material/'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import useHttp from '../../hooks/useHttp'
import { useForm, Controller } from 'react-hook-form'

const organizations = [
  { value: 1, label: 'Police' }
  , { value: 2, label: 'Healthcare' }
  , { value: 3, label: 'Insurance' }
]

export default function UserForm ({ isMobile, token, user }) {
  const { isLoading, error, sendRequest } = useHttp()
  const { _ } = useLingui()
  const { control, formState, handleSubmit, setValue, watch, reset: formReset } = useForm({ mode: 'onChange' })
  const passwordRef = useRef()
  const confirmPasswordRef = useRef()
  const [showOrg, setShowOrg] = useState(0)
  const [message, setMessage] = useState('')

  const handleMouseDownPassword = event => { event.preventDefault() }

  const watchOrganization = watch('organization')
  const watchNameAndSurname = watch(['name', 'surname'])

  // const isEmailPresent = user.email !== ''

  useEffect(() => {
    if (user && Object.keys(user).length) formReset(user)
  }, [user, formReset])

  useEffect(() => {
    if (!user && watchNameAndSurname.every(field => field?.trim())) {
      setValue('username', watchNameAndSurname.join('.').toLowerCase())
    }
  }, [user, watchNameAndSurname, setValue])

  useEffect(() => {
    if (watchOrganization !== 0) {
      setShowOrg(watchOrganization)
      if (watchOrganization === 1) setValue('hospital', '')
      if (watchOrganization === 2) setValue('department', '')
    }
  }, [watchOrganization, setValue])

  const onSubmit = async data => {
    if (Object.keys(formState.errors).length) return
    let body = {}
    let options = { httpMethod: 'POST' }
    if (user) {
      if (!Object.keys(formState.dirtyFields).length) return
      Object.keys(formState.dirtyFields)
        .forEach(key => { body = { ...body, [key]: data[key] } })
      options = {
        ...options
        , token
        // eslint-disable-next-line no-restricted-globals
        , url           : new URL('/api/user', location)
        , httpBody      : body
        , manageResData : res => {
          if (res.result.includes('success')) {
            setMessage(<Trans>User succefully modified!</Trans>)
          }
        }
      }
    } else {
      const {
        name
        , surname
        , username
        , phone
        , organization
        , department
        , email
        , password
        , confirmPassword
      } = data
      body = {
        name
        , surname
        , username
        , phone
        , organization
        , department
        , email
        , password
        , confirmPassword
      }
      options = {
        ...options
        // eslint-disable-next-line no-restricted-globals
        , url             : new URL('/api/register', location)
        , httpBody      : body
        , manageResData : res => {
          if (res.result.includes('success')) {
            setMessage(<Trans>
              User succefully recorded! Please <Link href='/'>Login</Link>
            </Trans>)
            formReset()
          }
        }
      }
    }
    console.log('OPTS', options)
    await sendRequest(options)
  }

  return (
    <Box
      width='100%'
      display='flex'
      flexDirection='column'
      alignItems='center'
      component='form'
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      mt='2rem'
    >
      <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`name`)}
            autoComplete="name"
            autoFocus
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Name is required`) }}
      />
      <Controller
        name="surname"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`surname`)}
            autoComplete="surname"
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Surname is required`) }}
      />
      <Controller
        name="username"
        control={control}
        defaultValue=''
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            disabled={token}
            fullWidth
            label={_(msg`username`)}
            autoComplete="username"
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ required: _(msg`Username is required`) }}
      />
      <Controller
        name="phone"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            disabled={token}
            fullWidth
            label={_(msg`Telephone number`)}
            autoComplete="phone"
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          required   : _(msg`Telephone number is required`)
          , pattern  : { value: /^[0-9]{6,12}$/, message: _(msg`Telephone number must be a number between 6 and 12 digits`) }
        }}
      />
      <Controller
        name="organization"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            disabled={token}
            fullWidth
            label={_(msg`Organization`)}
            autoComplete="organization"
            select
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          >
            {organizations.map(organization => (
              <MenuItem key={organization.value} value={organization.value}>
                {organization.label}
              </MenuItem>
            ))}
          </TextField>
        )}
        rules={{
          validate: (value, { hospital }) => !!value || !!hospital || _(msg`At least one of these fields is required`)
        }}
      />
      {
        {
          1: <Controller
            name="department"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                margin={isMobile ? 'dense' : 'normal'}
                required={!token}
                fullWidth
                label={_(msg`Department/Unit`)}
                autoComplete="department"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
            rules={{
              required: _(msg`Department/Unit is required`)
            }}
          />
          , 2: <Controller
            name="department"
            control={control}
            defaultValue=""
            render={({ field, fieldState }) => (
              <TextField
                margin={isMobile ? 'dense' : 'normal'}
                required={!token}
                fullWidth
                label={_(msg`Hospital/Health care facility`)}
                autoComplete="department"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
              />
            )}
            rules={{
              required: _(msg`Hospital/Health care facility is required`)
            }}
          />
        }[showOrg]
      }
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            margin={isMobile ? 'dense' : 'normal'}
            fullWidth
            disabled={token}
            label={_(msg`Email`)}
            autoComplete="email"
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{ pattern: { value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, message: _(msg`Email address is non valid`) } }}
      />
      <Controller
        name="password"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            inputRef={passwordRef}
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`New password`)}
            type={passwordRef.current?.type || 'password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    passwordRef.current.type === 'text'
                      ? (passwordRef.current.type = 'password')
                      : (passwordRef.current.type = 'text')
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {passwordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          minLength   : { value: 8, message: _(msg`Password must be at least 8 characters`) }
          , pattern   : {
            value     : /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])./ // (?!.* ) no spaces
            , message : _(msg`Password must contain at least an lowercase letter, an upper case letter and a digit`)
          }
        }}
      />
      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        render={({ field, fieldState }) => (
          <TextField
            inputRef={confirmPasswordRef}
            margin={isMobile ? 'dense' : 'normal'}
            required={!token}
            fullWidth
            label={_(msg`Confirm new password`)}
            type={confirmPasswordRef.current?.type || 'password'}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    confirmPasswordRef.current.type === 'text'
                      ? confirmPasswordRef.current.type = 'password'
                      : confirmPasswordRef.current.type = 'text'
                  }}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {confirmPasswordRef.current?.type === 'text' ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }}
            error={!!fieldState.error}
            helperText={fieldState.error ? fieldState.error.message : null}
            {...field}
          />
        )}
        rules={{
          minLength : { value: 8, message: _(msg`Password must be at least 8 characters`) }
          , validate  : (value, { password }) => value.trim() === password.trim() || _(msg`Passwords must match`)
          , pattern   : {
            value   : /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])./ // (?!.* ) no spaces
            , message : _(msg`Password must contain at least an lowercase letter, an upper case letter and a digit`)
          }
          // validate   : (value, formValues) => bool || msg
          // formValues are actual form values by name, can be destructured
        }}
      />
      {isLoading && <LinearProgress sx={{ mt: 2 }} />}
      {error && <Alert sx={{ mt: 2 }} severity="error">
        <Trans>There was an error: {error}.</Trans>
      </Alert>}
      {message && <Alert sx={{ mt: 2 }} severity='success'>
        {message}
      </Alert>}
      <Button
        type="submit"
        fullWidth={isMobile}
        variant="contained"
        color='primary'
        sx={{ mt: 3, mb: 2 }}
        disabled={token && !Object.keys(formState.dirtyFields).length}
      >
      {user ? <Trans>Save</Trans> : <Trans>Sign up</Trans>}
      </Button>
    </Box>
  )
}

// Example of validation schema using YUP
// const schema = yup.object().shape({
//   name              : yup.string().required(_(msg`Name is required`))
//   , surname         : yup.string().required(_(msg`Surname is required`))
//   , department      : yup.string().required()
//   , hospital        : yup.string().required(_(msg`Hospital/Health care facility is required`))
//   , phone           : yup.number(_(msg`Telephone number must be a number`)).positive().integer().required(_(msg`Telephone number is required`))
//   , password        : yup.string().required(_(msg`Password is required`))
//   , confirmPassword : yup.string().oneOf([yup.ref('password'), null], _(msg`Passwords must match`))
// })

// const { control, handleSubmit, setError, reset: resetForm } = useForm({
//   resolver: yupResolver(schema)
// })

// Solution to reset error on two related field simultaneously

// const watchEitherDeptOrHosp = watch(['department', 'hospital'])

// useEffect(() => {
//   if (watchEitherDeptOrHosp.some(field => field?.trim())) {
//     clearErrors(['department', 'hospital'])
//   }
// }, [watchEitherDeptOrHosp, clearErrors])

// Field validation
// rules={{
//   // validate: (value, { hospital }) => !!value || !!hospital || _(msg`At least one of these fields is required`)
// }}
// validate: (value, { department }) => !!value || !!department || _(msg`At least one of these fields is required`)
